import React from "react";
import HomePage from "./HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SceduleConsultation from "../Components/SceduleConsultation";
const Dashboard = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route exact path="/schedule" element={<SceduleConsultation />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
export default Dashboard;
