import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Config/theam";
import Dashboard from "./Pages/Dashboard";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Dashboard />
    </ThemeProvider>
  );
}

export default App;
