import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import emailjs from "emailjs-com";
import Footer from "./Footer";
import Navbar from "./Navbar/Navbar";
import React, { useRef, useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const SceduleConsultation = () => {
  const form = useRef();
  const [openNotification, setOpenNotification] = React.useState(false);
  const [errorNotification, setErrorNotification] = React.useState(false);
  const [formData, setFormdata] = React.useState({
    company_name: "",
    your_name: "",
  });
  const textChange = (event) => {
    setFormdata({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    localStorage.setItem("flag", true);
    return () => {
      localStorage.setItem("flag", false);
    };
  }, []);
  const sendEmail = (e) => {
    e.preventDefault();
    if (formData.company_name && formData.your_name) {
      emailjs
        .sendForm(
          "service_25ksgma",
          "template_0j5oqcg",
          form.current,
          "user_8nR6SbZuVra2HIkFJKIY4"
        )
        .then(
          (result) => {
            console.log(result.text);
            setOpenNotification(true);
            e.target.reset();
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      setErrorNotification(true);
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotification(false);
    setErrorNotification(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseNotification}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <div className="scedule-dashboard">
        <Navbar />
        <h1
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          className="dashboardContent"
        >
          Schedule a Free Consultation!
        </h1>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {/* notification */}
        <Snackbar
          open={errorNotification}
          autoHideDuration={4000}
          onClose={handleCloseNotification}
          action={action}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Company name & your name are mandatory
          </Alert>
        </Snackbar>{" "}
        <Snackbar
          open={openNotification}
          autoHideDuration={4000}
          onClose={handleCloseNotification}
          action={action}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Message sent successfully
          </Alert>
        </Snackbar>{" "}
        <Grid item sm={6} xs={10}>
          {" "}
          <form ref={form} onSubmit={sendEmail}>
            <h2 data-aos="fade-right" className="scedule-form-title">
              Ready to see what Accolibra Accounting can do for you?
            </h2>
            <p data-aos="fade-right" className="scedule-form-para">
              Let’s schedule a short introductory phone call so that we can
              answer your questions and we can get to know you and your
              business. It’s easy, please just use the form below and we will be
              in touch within 24 hours.
            </p>
            <TextField
              data-aos="fade-right"
              name="company_name"
              margin="normal"
              fullWidth
              size="small"
              label="Company name *"
              onChange={textChange}
            />
            <TextField
              data-aos="fade-right"
              name="your_name"
              margin="normal"
              fullWidth
              size="small"
              label="Your name *"
              onChange={textChange}
            />
            <TextField
              data-aos="fade-right"
              name="number"
              margin="normal"
              fullWidth
              size="small"
              label="Mobile number"
              type="number"
            />
            <TextField
              data-aos="fade-right"
              name="email"
              margin="normal"
              fullWidth
              size="small"
              label="Email"
              type="email *"
            />
            <TextField
              data-aos="fade-right"
              name="employee_count"
              margin="normal"
              fullWidth
              size="small"
              label="How many employees do you have?"
            />
            <TextField
              data-aos="fade-right"
              multiline
              rows={4}
              margin="normal"
              fullWidth
              size="small"
              label="Questions?"
              name="questions"
            />
            <Button
              data-aos="fade-right"
              style={{ textTransform: "none", minWidth: 100, marginBottom: 20 }}
              margin="normal"
              size="small"
              variant="contained"
              type="submit"
            >
              Submit <CircularProgress size="small" color="inherit" />
            </Button>{" "}
          </form>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};
export default SceduleConsultation;
