import { Container, Grid } from "@mui/material";
import React from "react";
import one from "../Assets/howitWorks/1.png";
import two from "../Assets/howitWorks/2.png";
import three from "../Assets/howitWorks/3.png";
import four from "../Assets/howitWorks/4.png";
import five from "../Assets/howitWorks/5.png";
import six from "../Assets/howitWorks/6.png";

import AOS from "aos";
const HowitWorks = () => {
  AOS.init({
    duration: 600,
  });
  return (
    <div className="howitworks-container">
      <Container maxWidth="large" style={{ textAlign: "center" }}>
        <h1 className="howitworks-head" data-aos="fade-right">
          What we do
        </h1>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="howwedo-icon"
        >
          <Grid item md={4} sm={6} xs={12}>
            <img data-aos="fade-right" style={{ maxWidth: 200 }} src={two} />
            <h3 className="howwedo-head" data-aos="fade-right">
              ACCOUNTING & BOOKKEEPING SERVICES
            </h3>
            <p className="howwedo-para" data-aos="fade-right">
              Outsourced Accounting and Book keeping services : Prepared and
              maintained by a team of highly qualified and efficient indiduals.
            </p>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <img data-aos="fade-right" style={{ maxWidth: 200 }} src={four} />
            <h3 className="howwedo-head" data-aos="fade-right">
              TAXATION-VAT, CORPORATE TAX & ITR FOR NRI'S
            </h3>
            <p className="howwedo-para" data-aos="fade-right">
              Meeting the compliance requirments, Tax review services & tax
              advisory services.
            </p>
          </Grid>{" "}
          <Grid item md={4} sm={6} xs={12}>
            <img data-aos="fade-right" style={{ maxWidth: 200 }} src={five} />
            <h3 className="howwedo-head" data-aos="fade-right">
              PAYROLL SERVICES
            </h3>
            <p className="howwedo-para" data-aos="fade-right">
              Payroll Services : Outsourced Payroll process and management
              services with our highly qualified professionals.
            </p>
          </Grid>{" "}
          <Grid item md={4} sm={6} xs={12}>
            <img data-aos="fade-right" style={{ maxWidth: 200 }} src={three} />
            <h3 className="howwedo-head" data-aos="fade-right">
              PLANNING, BUDGETING, FORECASTING & ANALYSIS
            </h3>
            <p className="howwedo-para" data-aos="fade-right">
              Guidance in moulding the future of your organisation.
            </p>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <img data-aos="fade-right" style={{ maxWidth: 200 }} src={six} />
            <h3 className="howwedo-head" data-aos="fade-right">
              FINANCIAL CONSULTANCY SERVICES
            </h3>
            <p className="howwedo-para" data-aos="fade-right">
              We simplify the financials and on time support in financial
              matters.
            </p>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <img data-aos="fade-right" style={{ maxWidth: 200 }} src={one} />
            <h3 className="howwedo-head" data-aos="fade-right">
              SECONDMENT SERVICES
            </h3>
            <p className="howwedo-para" data-aos="fade-right">
              Offshore Secondment services of proffesionally qualified and
              skilled staff for specific purpose and period.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default HowitWorks;
