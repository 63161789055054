import {
  Button,
  Container,
  Alert,
  Snackbar,
  Select,
  InputLabel,
  Grid,
} from "@mui/material";

import React, { useRef } from "react";
import emailjs from "emailjs-com";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AOS from "aos";
const WorkSchedule = () => {
  AOS.init({
    duration: 600,
  });

  const [constitution, setConstitution] = React.useState("");
  const [category, setCategory] = React.useState("");

  const handleChange = (event) => {
    setConstitution(event.target.value);
  };

  const categoryChange = (event) => {
    setCategory(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [errorNotification, setErrorNotification] = React.useState(false);
  const form = useRef();
  const [formData, setFormdata] = React.useState({
    company_name: "",
  });
  const textChange = (event) => {
    setFormdata({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotification(false);
    setErrorNotification(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseNotification}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(formData.company_name, "comName");
    if (formData.company_name.length) {
      emailjs
        .sendForm(
          "service_rs2jkjn",
          "template_kdzc9mu",
          form.current,
          "user_ngNIux3AEaImi58w5z8oZ"
        )
        .then(
          (result) => {
            setOpenNotification(true);
            e.target.reset();
            setOpen(false);
            e.target.reset();
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      setErrorNotification(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ textAlign: "center" }}>
      <Container maxWidth="false">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {" "}
          {/* notification */}
          <Snackbar
            open={errorNotification}
            autoHideDuration={4000}
            onClose={handleCloseNotification}
            action={action}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              Company name is mandatory
            </Alert>
          </Snackbar>
          <Snackbar
            open={openNotification}
            autoHideDuration={4000}
            onClose={handleCloseNotification}
            action={action}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Message sent successfully
            </Alert>
          </Snackbar>
          <Grid item xs={12}>
            <Button
              style={{ textTransform: "none", marginTop: 20 }}
              variant="contained"
              onClick={handleClickOpen}
            >
              Requirements Dropbox
            </Button>
          </Grid>
        </Grid>

        <div>
          <Dialog open={open} onClose={handleClose}>
            <form ref={form} onSubmit={sendEmail}>
              <DialogTitle data-aos="fade-right">
                {" "}
                Requirements Dropbox
              </DialogTitle>
              <DialogContent>
                {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item md={6} sm={6} xs={12}>
                    {" "}
                    <TextField
                      style={{ marginTop: 30 }}
                      margin="normal"
                      fullWidth
                      size="small"
                      label="Name of Company *"
                      type="text"
                      name="company_name"
                      onChange={textChange}
                      data-aos="fade-right"
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    {" "}
                    <InputLabel htmlFor="filled-age-native-simple">
                      Constitution of Business
                    </InputLabel>
                    <Select
                      native
                      name="const_business"
                      margin="normal"
                      size="small"
                      fullWidth
                      value={constitution}
                      onChange={handleChange}
                      inputProps={{
                        name: "const_business",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" />
                      <option value="LLC">LLC</option>
                      <option value="MNC">MNC</option>
                      <option value="LLP">LLP</option>
                      <option value="Pvt Ltd">Pvt Ltd</option>
                      <option value="OPG">OPG</option>
                      <option value="Partnership">Partnership </option>
                      <option value="Sole Proprietorship">
                        Sole Proprietorship
                      </option>
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    {""}
                    <TextField
                      margin="normal"
                      data-aos="fade-right"
                      fullWidth
                      size="small"
                      label="Turnover "
                      type="text"
                      name="turnover"
                      onChange={textChange}
                    />
                    <span
                      data-aos="fade-right"
                      style={{ fontFamily: "sans-serif", fontSize: 12 }}
                    >
                      (In order to determine the volume of business)
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    {" "}
                    <h3 data-aos="fade-right" style={{ textAlign: "left" }}>
                      Nature of Business
                    </h3>
                    <InputLabel
                      data-aos="fade-right"
                      htmlFor="filled-age-native-simple"
                    >
                      Category
                    </InputLabel>
                    <Select
                      native
                      name="category"
                      margin="normal"
                      size="small"
                      fullWidth
                      value={category}
                      onChange={categoryChange}
                      inputProps={{
                        name: "category",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" />
                      <option value="Retail">Retail</option>
                      <option value="MNC">Wholesale</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Service Sector">Service Sector</option>
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      data-aos="fade-right"
                      margin="normal"
                      label="Industry "
                      fullWidth
                      type="text"
                      name="industry"
                      size="small"
                      onChange={textChange}
                    />
                    <span
                      data-aos="fade-right"
                      style={{ fontFamily: "sans-serif", fontSize: 12 }}
                    >
                      (Eg : Petroleum,Agriculture,Textile etc.)
                    </span>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      data-aos="fade-right"
                      margin="normal"
                      id="name"
                      label="Email "
                      type="email"
                      fullWidth
                      name="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      data-aos="fade-right"
                      margin="normal"
                      id="name"
                      row={4}
                      label="Description "
                      fullWidth
                      type="text"
                      name="description"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button style={{ textTransform: "none" }} onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  style={{ textTransform: "none" }}
                  variant="contained"
                  type="submit"
                  value="Send"
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </div>
      </Container>
    </div>
  );
};
export default WorkSchedule;
