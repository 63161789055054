import { Button, Container, Alert, Snackbar, Grid } from "@mui/material";

import React, { useRef } from "react";
import emailjs from "emailjs-com";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AOS from "aos";
const Request = () => {
  AOS.init({
    duration: 600,
  });
  const [open, setOpen] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [errorNotification, setErrorNotification] = React.useState(false);
  const form = useRef();
  const [formData, setFormdata] = React.useState({
    company_name: "",
  });
  const textChange = (event) => {
    setFormdata({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotification(false);
    setErrorNotification(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseNotification}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const sendEmail = (e) => {
    e.preventDefault();
    if (formData.company_name.length) {
      emailjs
        .sendForm(
          "service_25ksgma",
          "template_b2cc80h",
          form.current,
          "user_8nR6SbZuVra2HIkFJKIY4"
        )
        .then(
          (result) => {
            console.log(result.text);
            setOpenNotification(true);
            e.target.reset();
            setOpen(false);
            e.target.reset();
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      setErrorNotification(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ textAlign: "center" }}>
      <Container maxWidth="false">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {" "}
          {/* notification */}
          <Snackbar
            open={errorNotification}
            autoHideDuration={4000}
            onClose={handleCloseNotification}
            action={action}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              Company name is mandatory
            </Alert>
          </Snackbar>
          <Snackbar
            open={openNotification}
            autoHideDuration={4000}
            onClose={handleCloseNotification}
            action={action}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Message sent successfully
            </Alert>
          </Snackbar>
          <Grid item xs={12}>
            <h2 data-aos="fade-right" className="scedule-form-title">
              Stop wasting time on bookkeeping
            </h2>
            <p data-aos="fade-right" className="scedule-form-para">
              Bookkeeping is not fun. Let’s take it off your hands.
            </p>
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              onClick={handleClickOpen}
              data-aos="fade-right"
            >
              Request Info
            </Button>
          </Grid>
        </Grid>

        <div>
          <Dialog open={open} onClose={handleClose}>
            <form ref={form} onSubmit={sendEmail}>
              <DialogTitle data-aos="fade-right">Request Info</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item md={6} sm={6} xs={12}>
                    {" "}
                    <TextField
                      margin="normal"
                      fullWidth
                      size="small"
                      label="Name"
                      type="text"
                      name="first_name"
                      data-aos="fade-right"
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    {" "}
                    <TextField
                      margin="normal"
                      fullWidth
                      data-aos="fade-right"
                      size="small"
                      label="Mobile number"
                      type="text"
                      name="last_name"
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    {" "}
                    <TextField
                      margin="normal"
                      data-aos="fade-right"
                      fullWidth
                      size="small"
                      label="Company name *"
                      type="text"
                      name="company_name"
                      onChange={textChange}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField
                      autoFocus
                      data-aos="fade-right"
                      margin="normal"
                      id="name"
                      label="Email "
                      type="email"
                      fullWidth
                      variant="standard"
                      name="email"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  data-aos="fade-right"
                  style={{ textTransform: "none" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  data-aos="fade-right"
                  style={{ textTransform: "none" }}
                  variant="contained"
                  type="submit"
                  value="Send"
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </div>
      </Container>
    </div>
  );
};
export default Request;
