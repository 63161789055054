import { Button, IconButton } from "@mui/material";
import * as React from "react";
import Navbar from "../Components/Navbar/Navbar";
import AboutUs from "../Components/AboutUs";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Request from "../Components/Request";
import WhatweDo from "../Components/WhatweDo";
import HowitWorks from "../Components/HowitWorks";
import alert from "../Assets/alert.jpeg";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HomePage = () => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  React.useEffect(() => {
    handleClickOpen();
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          {" "}
          <DialogTitle disableTypography>
            <IconButton onClick={handleClose}>
              <CloseIcon color="primary" size="small" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <img className="alert-home-img" src={alert}></img>
          </DialogContent>
        </Dialog>
      </div>
      <div className="dashboard">
        <Navbar />
        <p
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          className="dashboardContent"
        >
          Accolibra offers outsourced accounting and
          <br /> tax compliance services for small <br />
          and medium size business.
        </p>
        <Link to="/schedule" style={{ textDecoration: "none" }}>
          <div className="dashboardbutton">
            <Button
              style={{ textTransform: "none" }}
              size="medium"
              variant="contained"
              color="primary"
            >
              Schedule Consultation
            </Button>
          </div>
        </Link>{" "}
        <h3 className="Service-start-head">Services starts from 500AED*</h3>
        <h5 className="Service-start-content">
          *Quarterly VAT Reporting @250AED
        </h5>
      </div>
      <div className="request-container">
        <Request />
      </div>
      <div id="about-us">
        <AboutUs />
      </div>
      <div id="our-services">
        <HowitWorks />
      </div>
      <div>
        <WhatweDo />
      </div>
      <div id="contact-us">
        <Footer />
      </div>
    </div>
  );
};
export default HomePage;
