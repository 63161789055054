import { Container, Grid } from "@mui/material";
import React from "react";
import AOS from "aos";
import WorkSchedule from "./WorkSchedule";
const WhatweDo = () => {
  AOS.init({
    duration: 600,
  });
  return (
    <div className="whatwedo-container">
      <Container maxWidth="large" style={{ textAlign: "center" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className="howwedo-icon"
        >
          <Grid item xs={12}>
            <p className="whatwedo-content-head" data-aos="fade-right">
              Accolibra offers Financial advisory services by Professionals
              along with Bookkeeping and Tax compliance services
            </p>
          </Grid>
        </Grid>
        <WorkSchedule />
      </Container>
    </div>
  );
};
export default WhatweDo;
