import { Grid, Typography } from "@mui/material";
import React from "react";
import map from "../Assets/about-image.png";
import AOS from "aos";

const AboutUs = () => {
  AOS.init({
    duration: 600,
  });
  return (
    <div>
      <p className="common-head" data-aos="fade-right">
        {" "}
        ABOUT US
      </p>
      <hr className="common-devider" data-aos="fade-right" />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        // spacing={5}
      >
        <Grid className="gridMap" item md={6} xs={12} sm={6}>
          <img className="imgMap" data-aos="fade-right" src={map} />
        </Grid>
        <Grid className="gridAbout" item md={6} xs={12} sm={6}>
          <p className="paraAbout" data-aos="fade-right">
            {" "}
            AccoLibra was established in 2020 by partners with strong knowledge
            and experience in the provision of professional services. Our
            partners have been working with different local and multinational
            companies over the last twenty years before establishing AccoLibra.
            Currently, AccoLibra represents a team of specialists with strong
            professional background in different industry sectors with a focus
            on offshore Accounting services, Tax services, and Financial
            Consultancy services. We are teaming up with different qualified
            accounting firms in India and Middle East who have broad knowledge
            of both practical and compliance aspects of the local business
            environment. By gathering all these professionals in one strong team
            as AccoLibra and providing their experience, knowledge, skills, and
            capabilities at our client’s disposal, we give the opportunity for
            our clients to receive high quality services.
          </p>{" "}
        </Grid>
      </Grid>
    </div>
  );
};
export default AboutUs;
